import React, { useEffect, Fragment } from "react";
import "../styles/skills.scss";

const SlideIn: React.FC = () => {
    
    useEffect(() => {
        const sliders = document.querySelectorAll('.slide-in');
        
        const appearOnVisible = new IntersectionObserver(function(entries, appearOnVisible) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add("appear")
                    appearOnVisible.unobserve(entry.target);
                }
            })
        })

        sliders.forEach(slider => {
            appearOnVisible.observe(slider)
        })

    }, );
    
    return (<Fragment></Fragment>)
}

export default SlideIn;