import React, { Fragment, useEffect, useState } from "react";
import "../styles/aboutMe.scss";

const AppearingObjects: React.FC = () => {
// const [isVisible, setIsVisible] = useState(false)
    
//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll);
        
//         return () => {
//             window.removeEventListener('scroll', handleScroll)
//         };
//     }, []); 


    // const handleScroll = () => {
    //     // const scrolled = window.scrollY;
    //     // const object = document.querySelector('.scrolling-content');
    //     // const scrollY = window.scrollY || document.documentElement.scrollTop;
    //     // const scrollThreshold = 350;

    //     // setIsVisible((scrollY > scrollThreshold))

    //     // if(object) {
    //     //     object.style.transform = `translateX(${scrolled}px)`;
    //     // }
    // }


useEffect(() => {   
    const faders = document.querySelectorAll('.fade-in');

    const appearOptions = {
        threshold: 1,
        rootMargin: "0px 0px -310px 0px"
    };

    const appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear");
                appearOnScroll.unobserve(entry.target);
            }
        })
    }, appearOptions)

    faders.forEach(fader => {
        appearOnScroll.observe(fader);
    });
})

    return (<Fragment></Fragment>)
};

export default AppearingObjects;