import React from "react";
import "../styles/contact.scss";
import {Form, UserData} from "../components/ContactForm";

const Contact: React.FC = () => {

    function handleSubmit(formData: UserData) {
        console.log(formData);
    }

  return (
    <div className="container">
      <h4>CONTACT ME</h4>
      <div>
        <Form onSubmit={handleSubmit} />
      </div>

    </div>
  );
};

export default Contact;
