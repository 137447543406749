import React, { Fragment } from "react";
import "../styles/experience.scss";

const Experience: React.FC = () => {


    return ( 
    <Fragment>
        <div id="experience-section" className="section" >
        <div className="experience-waves top"></div>
        <div className="description-wrapper">
                <header className="experience-header">Experience</header>
                <p className="experience-description">
                    
                    I think a lot about the "curb-cut effect"; an idea inspired by replacing 6 inches of a concrete curb into a slope on the sidewalks. A redesign that makes it easier for people with a disability to get around, and in turn makes it easier for everyone.  
                    <br/>
                    <br/>
                    I worked at a tech agency for two years, which required getting up to speed on a new tech stack with every project. With these opportunities, I hope to create a lot of curb cuts in tech and make it easier for everyone to connect.
                </p>
        </div>
        <div className="experience-waves bottom"></div>
            <div className="experience-wrapper">
                <div id="timeline">
                    <div className="timeline-block">
                        <div className="timeline-content">
                        <div className="timeline-img">
                                <div className="sprite school"></div>
                            </div>
                            <h2 className="timeline-position">Student</h2>
                            <h3 className="timeline-header">Kansas City Art Institute</h3>
                            <p className="timeline-info">Studied design, color theory, and composition.</p>
                        </div>
                        <span className="date">Aug 2018 - Dec 2020</span>
                    </div>
                    <div className="timeline-block">
                        <div className="timeline-content">
                        <div className="timeline-img">
                                <div className="sprite launchcode"></div>
                            </div>
                            <h2 className="timeline-position">Student</h2>
                            <h3 className="timeline-header">The LaunchCode Foundation</h3>
                            <p className="timeline-info">A comprehensive web development course covering Java, Javascript, CSS, HTML, Angular, and SQL basics. Students demonstrate their skills through a final project of their choice and are then guided into an apprenticeship program.</p>
                        </div>
                        <span className="date">Jun 2021 - Dec 2021</span>
                    </div>
                    <div className="timeline-block">
                        <div className="timeline-content">
                        <div className="timeline-img">
                                <div className="sprite palette"></div>
                            </div>
                            <h2 className="timeline-position">Graphic Designer</h2>
                            <h3 className="timeline-header">Corporate CopyPrint</h3>
                            <p className="timeline-info">Leveraged Adobe Creative Suite for designing advertising materials and product concepts, enhancing client engagement that led to repeat business and contributed to the growth of our team's department.</p>
                        </div>
                        <span className="date">Jun 2021 - Mar 2022</span>
                    </div>
                    <div className="timeline-block">
                        <div className="timeline-content">
                            <div className="timeline-img">
                                <div className="sprite terminal"></div>
                            </div>
                            <h2 className="timeline-position">Software Engineer</h2>
                            <h3 className="timeline-header">Viagio Technologies</h3>
                            <p className="timeline-info">Improved educational and business software by adding new features and streamlining processes. Quickly adapted to new technologies, leading projects to successful completion. Enhanced system stability and ensured high-quality software through efficient testing and collaboration, contributing to overall adaptability and performance in diverse development settings.</p>
                        </div>
                        <span className="date">Apr 2022 - Aug 2022</span>
                    </div>
                    <a className="resume-download" href="/ZoeDonovanWebsiteDownload.pdf" download="ZoeDonovan.pdf" >
                        <div className="timeline-block">
                            <div className="timeline-content resume">
                                <div className="timeline-img">
                                    <div className="sprite description"></div>
                                </div>
                                <h2 className="timeline-resume">Resume</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </Fragment>  );
}

export default Experience;