import React, { Component, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import 'reset-css';
import './styles/App.scss';
import './styles/globalStyles.scss'
import NavBar from './components/NavBar';
import './Theme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutMePage from './views/aboutMe';
import Contact from './views/Contact';
import Skills from './views/Skills';
import Footer from './views/Footer';
import Experience from './views/Experience';
import reportWebVitals from './reportWebVitals';


ReactGA.initialize('G-G6FLRQ1S8L');
const navigation = {
  brand: { name: "Welcome", to: "#App" },
  links: [
    { name: "About Me", to: "#aboutMe" },
    { name: "Skills", to: "#skillsSection" },
    { name: "Experience", to: "#expSection" },
    { name: "Contact", to: "#contactSection" }
  ]
}
 
export default function App() {

  const [atTop, setAtTop] = useState(true);
  const navBarRef = useRef(null);

  window.onscroll = function() {setAtTop(true)}
  var scrollingNav = document.getElementById("scrollNav")

  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setAtTop(true)
        scrollingNav?.classList.remove("sticky-nav");
      } else { 
        setAtTop(false)
        scrollingNav?.classList.add("sticky-nav")
      }
    }
    window.addEventListener("scroll", handleScroll);

  }, [atTop]);
    
    const { brand, links } = navigation;

    const SendAnalytics = () => {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }
    reportWebVitals(SendAnalytics);

    return (
      <div className='app-body'>
        <BrowserRouter>
        <div className="App">
              <NavBar brand={brand} links={links}/>
              <Routes>
                <Route path="/about" Component={AboutMePage} />
                <Route path="/skills" Component={Skills} />
                <Route path="/experience" Component={Experience} />
                <Route path="/contact" Component={Contact} />
    
              </Routes>
        <section id='aboutMe'>
          <AboutMePage />
        </section>
        <section id='skillsSection' />
          <Skills />
        </div>
        </BrowserRouter>
        <section id='expSection' />
          <Experience />
        <div className='waves-svg'></div>
        <section id='contactSection'>
          <Contact />
        </section>
        <Footer />
      </div>
    );

  }




