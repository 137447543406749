import React from "react";

interface FormProps {
    onSubmit: (data: UserData) => void;
}

export interface UserData {
    name: string;
    email: string;
    message: string;
}

export const Form: React.FC<FormProps> = ({onSubmit}) => {
    const [UserData, setUserData] = React.useState<UserData>({
        name: '',
        email: '',
        message: ''
    });

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setUserData((UserData) => ({
            ...UserData,
            [name]: value,
        }));
    }

    function handleMessageChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const {name, value} = event.target;
        setUserData((UserData) => ({
            ...UserData,
            [name]: value,
        }));
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        onSubmit(UserData);
    }

    return (
    <form  action="https://formsubmit.co/fff431a3ecc8c12f852cd25c49ea4b68" method="POST" >
        <input type="text" name="name" value={UserData.name} onChange={handleInputChange} placeholder="Name" required />
        <input type="email" name="email" value={UserData.email} onChange={handleInputChange}  placeholder="Email" required />
        <textarea name="message" value={UserData.message} onChange={handleMessageChange}  placeholder="Your Message" required />
        <button className="button-submit" type="submit">SUBMIT</button>
    </form>
    );
}
 
export default Form;