import React from "react";
import styled from 'styled-components'
import AboutMePage from '../views/aboutMe';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../styles/navbar.scss';

const NavBar = (props: {
     brand: { name: string, to: string}
     links: Array<{ name: string; to: string}> 
    }) => {
    const { brand, links } = props;
    const NavLinks: any = () => links.map((link: {name: string, to: string }) => <li className='liNav' key={link.name}><a href={link.to}>{link.name}</a>
    </li>);
    return (
        <nav id="scrollNav">
            <div className="welcome" >{brand.name}</div>
            <ul>
            <NavLinks />
            </ul>
        </nav>
    );
};

export default NavBar;