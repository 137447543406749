import React, { Fragment, useEffect, useState, useRef } from "react";
import data from "../config/skillsData.json";
import "../styles/skills.scss";
import AppearingObjects from "../components/AppearingObjects";
import ExpandSkills from "../components/ExpandSkill";
import SlideIn from "../components/SlideIn";

 
const Skills: React.FC = () => {
    
    let skills = ["Languages", "IDEs", "Environment Setup","Frameworks", "Project Management","Mobile Development","Web Development", "Deployment"]

    const [showContent, setShowContent] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isItemClicked, setIsItemClicked] = useState(false);
    var selectedContent = data.find(obj => obj.key === data[selectedIndex].key)
    var selectedImg = selectedContent?.contentImg

    const handleClick = (skillIndex: number) => {
        setIsItemClicked(true);
        setSelectedIndex(skillIndex);
        setShowContent(showContent);
    }

    function textWithLineBreaks(prop: String) { // todo: combine this with barChart Animation to clean up return HTML script
        const textWithBreaks = prop.split('\n').map((text, index) => (
        <Fragment key={index}>
            {text}
            <br />
        </ Fragment>
        ))
        return <div className="bulletText">{textWithBreaks}</div>
    };

    function barChartAnimation(barText: String[], content: string) {
        var widths = content.split(',')

        const bar = {
            barText: barText,
            width: widths
        }

        const barChartTest = bar.barText.map((text, index) => (
            <div  className="skill-bar" >
                    <div className="fill" key={index} style={{width: widths[index]}} >
                    <div className="skill-tag">{text}</div>
                </div>
            </div>
        ))

        return <div className="flex bar-wrapper">{barChartTest}</div>
    };

    useEffect(() => {
        if (isItemClicked) {
            setTimeout(() => {
                setIsItemClicked(false)
            }, 100000)
        }
    }, [isItemClicked]);

    useEffect(() => {
        const sliders = document.querySelector('.slide-in');
        if (!isItemClicked){
            const interval = setInterval(() => {
                setTimeout(() => { sliders?.classList.remove("appear"); 
                    setTimeout(() => { setSelectedIndex((prevIndex) => prevIndex === skills.length - 1 ? 0 : prevIndex + 1)
                        sliders?.classList.add("appear")
                    }, 1800)
                }, 1000)
            }, 10000);
            return () => clearInterval(interval)
        }
    }, [selectedIndex, skills.length, isItemClicked]);

    return (  
        <Fragment>
            <body className="skills-body">
                <AppearingObjects />
                <SlideIn />
                <div className='content-width'>
                    <header className="fade-in skills-header">Skills</header>
                    <div className='grid'>
                        {skills.map((skill, index) => (
                            <li
                                className={`fade-in skills ${ selectedContent?.key == skill ? 'activeSkill' : ''}` }
                                key={skill}
                                onClick={() => {handleClick(index);}}
                            >
                                {skill}
                            </li>
                        ))}
                    </div>
                    {showContent && <div  className="columns slide-in from-right flex flex-row">
                        <div className="col content-container flex-50">
                            <header className="skills-header">{selectedContent?.key}</header>
                            <div className="skill-content">{selectedContent != null ? ( selectedContent.style == 0 ? (selectedContent.skills.join(" · ")) : "" ) : "" }
                            </div>
                            <br />
                            <div>{selectedContent != null ? ( selectedContent.style == 0 ? (textWithLineBreaks(selectedContent.content)) : (barChartAnimation(selectedContent.skills, selectedContent.content)) ) : "" }</div>
                        </div>
                        <div className="skill-image flex-50"><img src={selectedImg}  className="flex clipped" /></div>
                    </div>
                    }
                </div>
            </body>
        </Fragment>
    );
}
 
export default Skills;