import React from "react";
import linkedInIcon from "../public/linkedin-logo.png";
import githubIcon from "../public/github-mark.png"
import "../styles/footer.scss";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="row">
        <a className="iconLink" href="https://link.youfoundzoe.com/LinkedIn">
          <img className="footer-img" src={linkedInIcon} />
        </a>
        <a className="iconLink" href="https://link.youfoundzoe.com/GitHub">
          <img className="footer-img" src={githubIcon} />
        </a>
      </div>
      <p>Zoe Richardson 2023</p>
    </footer>
  );
};

export default Footer;
